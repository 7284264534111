.printIcon {
  margin-right: 25px;
  cursor: pointer;
}

.downloadIcon {
  margin-right: 25px;
  margin-left: 25px;
  cursor: pointer;
}

.browseLotIcons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -14px !important;
  margin-bottom: 33px !important;
}
.winners-info-header-left {
}
.Usertable-info .Paginationbtns {
  padding-top: 15px;
}
.winners-info-header-body {
  background-color: #fff;
}
.winners-info-header-left,
.winners-info-header-body1,
.image-sec-info-winners {
  display: flex;
  gap: 20px;
}
.winners-info-header {
  display: flex;
  background: #f9f9f9;
  padding: 10px 10px;
  justify-content: space-between;
}
.winners-info-header p {
  margin-bottom: 0px;
}
.expand-sec-content-row-sec {
  padding: 10px 30px;
  background: #fff;
}
.summary-sec-info-winners {
  min-width: 350px;
  padding: 10px 0px;
  padding-right: 10px;
}
.dotted-lines-sec {
  border-top: 3px dotted;
  margin-bottom: 10px;
}
.winners-info-header-body1 {
  justify-content: space-between;
}
.row-box-winners {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.09);
}
.image-sec-info-winners img {
  min-width: 100px;
  max-height: 100px;
}
.summary-sec-info-winners > div {
  display: flex;
  justify-content: space-between;
  /* padding: 5px; */
}
.image-sec-info-winners {
  padding: 10px;
}

.winners-info-header-left {
  /* padding-top: 10px; */
}
.all-list-all-reports {
  display: grid;
  margin-top: 20px;
  gap: 16px;
  margin-bottom: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.all-list-all-reports > div {
  padding: 20px 25.5px 19px 25.5px;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  text-align: center;
  background: #f5f5f5;
}
